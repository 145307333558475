import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {FirebaseAuthenticationService} from "./firebase-authentication.service";
import {from, Observable, switchMap} from "rxjs";
import {printStackTrace} from "../../utils/debug";


@Injectable()
export class FirebaseAuthenticatorInterceptor implements HttpInterceptor {

  constructor(
    private authService: FirebaseAuthenticationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Convert getToken (which returns a Promise) to an Observable
    return from(this.authService.getToken()).pipe(
      switchMap(token => {
        if (token) {
          // console.log("Adding bearer token to request. Token: " + token)
          console.log(request.method)
          console.log(request.url)
          // printStackTrace();

          // Clone the request to add the new header
          const clonedRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
          // console.log(clonedRequest);
          // Pass the cloned request instead of the original request to the next handler
          return next.handle(clonedRequest);
        } else {

          // console.log(request)

          // If there's no token, just forward the original request
          return next.handle(request);
        }
      })
    );
  }
}
