import { inject, Injectable } from '@angular/core';
import { UserStateApiService } from './user-state-api.service';
import { Observable } from 'rxjs';
import { ContentPurchase } from './domain/content-purchase';
import { SubscriptionRequest } from './domain/subscription-request';
import { HttpClient } from '@angular/common/http';
import { UserApi } from './user-api';
import { RootResourceId } from '../../community/domain/resource';
import { AddToCommunity } from './domain/add-to-community';

@Injectable({
  providedIn: 'root'
})
export class UserStateApiServiceImplementation extends UserStateApiService {
  private readonly httpClient = inject(HttpClient);

  override grantAccessToFeed(contentElement: ContentPurchase): Observable<void> {
    return this.httpClient.post<void>(UserApi.grantAccessToFeed, contentElement);
  }

  override grantAccessToCollection(contentElement: ContentPurchase): Observable<void> {
    return this.httpClient.post<void>(UserApi.grantAccessToCollection, contentElement);
  }

  override assignSubscription(subscription: SubscriptionRequest): Observable<void> {
    return this.httpClient.post<void>(UserApi.assignSubscription, subscription);
  }

  override addCreatorToCommunity(userEmail: string, communityId: RootResourceId): Observable<void> {
    const data: AddToCommunity = {
      userEmail,
      communityId
    }
    return this.httpClient.post<void>(UserApi.addToCommunity, data);
  }
}
