<form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()" class="container mt-3">

  <div *ngIf="isSignUpMode" class="form-group mb-3">
    <label for="firstName">First Name</label>
    <input id="firstName" type="text" formControlName="firstName" class="form-control">
  </div>
  <div *ngIf="isSignUpMode" class="form-group mb-3">
    <label for="name">Name</label>
    <input id="name" type="text" formControlName="name" class="form-control">
  </div>

  <div class="form-group mb-3">
    <label for="email">Email *</label>
    <input id="email" type="email" formControlName="email" required class="form-control">
  </div>

  <div *ngIf="!isForgotPasswordMode" class="form-group pb-5">
    <label for="password">Password *</label>
    <div *ngIf="isSignUpMode" class="password-caption text-body-secondary mb-2">Choose a password that is at least 6
      characters long
    </div>
    <input id="password" type="password" formControlName="password" required class="form-control">
    <div class="d-flex justify-content-end">
      <button *ngIf="!isSignUpMode && !isForgotPasswordMode" type="reset" class="btn btn-link"
              (click)="onForgotPasswordClicked()">Forgot Password?
      </button>
    </div>
  </div>

  <div *ngIf="!isSignUpMode && !isForgotPasswordMode">
    Don't have an account yet?
  </div>
  <div *ngIf="isSignUpMode && !isForgotPasswordMode">
    Already have an account?
  </div>
  <div class="d-flex justify-content-between pb-3">
    <button *ngIf="!isSignUpMode && !isForgotPasswordMode" type="button" class="btn btn-link" (click)="toggleMode()">
      Switch to Sign Up
    </button>
    <button *ngIf="isSignUpMode && !isForgotPasswordMode" type="button" class="btn btn-link" (click)="toggleMode()">
      Switch to Log In
    </button>
    <button *ngIf="isForgotPasswordMode" type="button" class="btn btn-link" (click)="onResetPasswordBackClicked()">
      Back
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="!credentialsForm.valid">
      {{ isForgotPasswordMode ? 'Reset Password' : isSignUpMode ? 'Sign Up' : 'Log In' }}
    </button>
  </div>

  <div *ngIf="signupSuccess" class="alert alert-success animated fadeIn">
    Thank you, you are registered successfully. You will receive a verification email shortly. Please, click on the
    link in the email to complete sign up.
  </div>

  <div *ngIf="resetPasswordSuccess" class="alert alert-success animated fadeIn">
    You successfully initialized the password reset. You will receive an email shortly. Please follow the
    instructions in the email to create a new password.
  </div>

  <div *ngIf="signupError" class="alert alert-danger animated shake">
    We're very sorry, we're having some technical difficulties signing you up. Please contact us by email, and we'll
    sort out the problem: <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a> <br><br>
    Detailed error: {{ signupError }}
  </div>

  <div *ngIf="loginError" class="alert alert-danger animated shake">
    {{ loginError }}
  </div>

  <div *ngIf="forgotPasswordError" class="alert alert-danger animated shake">
    {{ forgotPasswordError }}
  </div>
</form>
