import {Injectable} from "@angular/core";
import {CollectionService} from "./collection.service";
import {Collection} from "../domain/collection";
import {Observable} from "rxjs";
import {Resource, ResourceId} from "../domain/resource";
import {Instant} from "../domain/instant";
import {CommunityApi} from "../community.api";
import {HttpClient} from "@angular/common/http";
import {CommunityDelegateHttpService} from "../access-control/community-delegate-http.service";

@Injectable({
  providedIn: 'root'
})
export class CollectionServiceImplementation extends CollectionService {

  constructor(private httpClient: CommunityDelegateHttpService) {
    super();
  }

  create(assignmentId: string, collection: Collection): Observable<Resource<Collection>> {
    return this.httpClient.post<Resource<Collection>>(CommunityApi.createCollection(assignmentId), collection);
  }

  get(reference: ResourceId): Observable<Resource<Collection>> {
    return this.httpClient.get<Resource<Collection>>(CommunityApi.getCollection(reference));
  }

  getSlice(instant: Instant, count: number, ascending: boolean): Observable<Resource<Collection>[]> {
    return this.httpClient.get<Resource<Collection>[]>(
      CommunityApi.getCollections(instant.assignmentId, instant.timestamp),
      {params: {ascending: ascending}}
    );
  }

  save(resource: Resource<Collection>): Observable<Resource<Collection>> {
    return this.httpClient.put<Resource<Collection>>(
      CommunityApi.putCollection(resource.assignmentId, resource.creationTimestamp, resource.uuid),
      resource.data
    );
  }
}
