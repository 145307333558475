import {CanActivateChildFn, CanActivateFn} from "@angular/router";
import {inject} from "@angular/core";
import {LoginModalService} from "./login/login-modal/login-modal.service";
import {map} from "rxjs";
import { AuthenticationService } from './authentication/authentication.service';

export const authenticationGuard: CanActivateFn = (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const loginModalService = inject(LoginModalService);

  return authenticationService
    .isAuthenticated()
    .pipe(
      map((isAuthenticated => {
        if (!isAuthenticated) {
          // loginModalService.showModal();
          loginModalService.loginAndRoute(state.url);
        }
        return isAuthenticated;
      }))
    )
}

export const authenticationGuardChild: CanActivateChildFn = (route, state) => authenticationGuard(route, state);
