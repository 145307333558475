import {Injectable, NgZone} from '@angular/core';

import {initializeApp} from "firebase/app";
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User
} from "firebase/auth";
import {environment} from "../../../environments/environment";
import {map, Observable, ReplaySubject} from "rxjs";
import {AuthenticationService} from '../authentication.service';
import {UserService} from '../../login/user.service';
import {Router} from "@angular/router";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthenticationService extends AuthenticationService {
  auth: Auth;
  // private _authStateSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private _authStateSubject: ReplaySubject<User | null> = new ReplaySubject<User | null>(1);

  constructor(
    private readonly customerService: UserService,
    zone: NgZone,
  ) {
    super();
    const app = initializeApp(environment.firebaseConfig);
    this.auth = zone.runOutsideAngular(() => getAuth(app));
    // this._authStateSubject.next(this.auth.currentUser);
    onAuthStateChanged(this.auth, user => {
      this._authStateSubject.next(user);
    });
  }

  override signUp(email: string, password: string, firstName?: string, name?: string): Observable<void> {
    return this.customerService.create({email, password, firstName, name});
  }

  // async login(email: string, password: string): Promise<void> {
  //   await signInWithEmailAndPassword(this.auth, email, password)
  //     .then(userCredentials => userCredentials.user.emailVerified);
  // }

  async login(email: string, password: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const userCredentials = await signInWithEmailAndPassword(this.auth, email, password);
        const user = userCredentials.user;

        if (!user.emailVerified) {
          // todo: this might show the resend verification button
          reject(new Error('Email not verified. Please check your email inbox to  verify your email.'));
        } else {
          resolve();
        }
      } catch (error) {
        reject(error);
      }
    });
  }

//   async setCookie(){
//     // As httpOnly cookies are to be used, do not persist any state client side.
//     // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
//
// // When the user signs in with email and password.
//     signInWithEmailAndPassword(this.auth, 'user@example.com', 'password').then(user => {
//       // Get the user's ID token as it is needed to exchange for a session cookie.
//       return user.user.getIdToken().then(idToken => {
//         // Session login endpoint is queried and the session cookie is set.
//         // CSRF protection should be taken into account.
//         // ...
//         const csrfToken = getCookie('csrfToken')
//         return postIdTokenToSessionLogin('/sessionLogin', idToken, csrfToken);
//       });
//     }).then(() => {
//       // A page redirect would suffice as the persistence is set to NONE.
//       return firebase.auth().signOut();
//     }).then(() => {
//       window.location.assign('/profile');
//     });
//   }

  async logout() {
    return signOut(this.auth); //.then(() => window.location.reload());
  }

  async resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public isAuthenticated(): Observable<boolean> {
    return this.authState$.pipe(map(user => user !== null && user.emailVerified))
  }

  public get authState$() {
    return this._authStateSubject.asObservable();
  }

  async getToken() {
    return new Promise((resolve, reject) => {
      this.authState$.subscribe((user) => {
        if (user) {
          // User is signed in, get the ID token
          getIdToken(user).then((idToken) => {
            resolve(idToken);
          }).catch((error) => {
            console.log("Rejecting token request: " + error)
            reject(error);
          });
        } else {
          // todo: remove me
          console.log("User not signed in.")
          // User is signed out
          // reject('Not signed in');
          resolve(null);
        }
      });
    });
  }
}
