<!--<app-staging-banner></app-staging-banner>-->
<div id="outer" class="d-flex flex-column vh-100 overflow-hidden" data-bs-theme="dark">
  <header id="header" class="flex-shrink-0" (click)="toggleCollapsed()">
    <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: var(--bs-primary);">
      <div class="container-fluid d-flex flex-row">

        <button class="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar links -->
          <div class="justify-content-end" id="navbarSupportedContent" [ngClass]="{'collapse': navbarCollapsed, 'navbar-collapse': true}">
          <ul class="navbar-nav mb-2 mb-lg-0">
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" [routerLink]="AppRoutes.community + '/profile'" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">Discover</a>-->
<!--            </li>-->
            <li class="nav-item">
              <a class="nav-link" [routerLink]="AppRoutes.myContent" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">My Content</a>
            </li>
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" routerLink="/feed-creator" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">FeedCreator</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" routerLink="/plans" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">Plans</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" [routerLink]="AppRoutes.legal" routerLinkActive="nav-link-active" ariaCurrentWhenActive="page">Legal</a>-->
<!--            </li>-->
            <li class="nav-item">
              <div *ngIf="!(authenticationService.isAuthenticated() | async) else logout">
                <button class="btn" (click)="loginModalService.login()">Login</button>
              </div>
              <ng-template #logout>
                <button class="btn" (click)="authenticationService.logoutAndRoute()">Logout</button>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <main class="d-flex flex-grow-1 overflow-hidden">
      <router-outlet></router-outlet>
  </main>
<!--  <footer id="footer" class="mt-2" *ngIf="showFooter" [ngClass]="{'page-loaded': pageLoaded}">-->
<!--    <div class="container">-->
<!--      <div class="row mb-3">-->
<!--        <div class="col-lg-6">-->
<!--          <p class="mb-0">-->
<!--            <span class="gray">More information:</span><br>-->
<!--            <a href="mailto:contact@keldysh.io">contact&#64;keldysh.io</a>-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="col-lg-6 text-lg-end">-->
<!--          <p class="mb-0">-->
<!--            <span class="gray">A concept by</span><br>-->
<!--            <a href="keldysh.io">keldysh.io</a>-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </footer>-->
    <app-login-modal></app-login-modal>
    <app-checkout-modal></app-checkout-modal>
    <app-loading></app-loading>
    <app-action-confirmation-modal></app-action-confirmation-modal>
</div>
