<div *ngIf="selection">
  <p>Your current selection:
    <strong>{{ selection.product.name }} | Billing period: {{ selection.price.billingPeriod }}</strong>
  </p>
  <p>Billing: <strong>{{ selection.tryout ? selection.price.tryoutPeriod + ' days free, then ' : '' }}</strong>
    <strong>{{ selection.price.valueInChf ? selection.price.valueInChf / 100 : 0 }} CHF</strong></p>
  <!--        <p *ngIf="price?.tryoutPeriod > 0">This plan includes a <strong>{{ price?.tryoutPeriod }}-day free trial.</strong></p>-->
  <div class="mb-3">
    <label for="quantity" class="form-label">Quantity:</label>
    <input type="number" class="form-control" id="quantity" [(ngModel)]="selection.quantity" min="1">
  </div>
  <div class="d-flex justify-content-between">
    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="submit()">Confirm</button>
  </div>
  <div *ngIf="submitError" class="alert alert-danger animated shake">
    <strong [innerHTML]="submitError"></strong>
  </div>
</div>
