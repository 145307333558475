import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export abstract class AuthenticationService {

  logoutAndRoute() {
    this.logout()
      .then(() => {
        window.location.href = ''
      })
  }

  abstract signUp(email: string, password: string, firstName?: string, name?: string): Observable<void>;

  abstract login(email: string, password: string): Promise<void>;

  abstract logout(): Promise<void>;

  abstract resetPassword(email: string): Promise<void>;

  abstract isAuthenticated(): Observable<boolean>;
}
