import { ApplicationConfig, SecurityContext } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import { FirebaseAuthenticatorInterceptor } from "./authentication/firebase/firebase-authenticator.interceptor";
import { loadingInterceptor } from "./loading/loading.interceptor";
import { provideMarkdown } from "ngx-markdown";
import { PostService } from "./community/post/post.service";
import { FeedService } from "./community/feed/feed.service";
import { CollectionService } from "./community/collection/collection.service";
import { ProfileService } from "./community/profile/profile.service";
import { ResourceAccessService } from './community/access-control/resource-access.service';
import { AuthenticationService } from './authentication/authentication.service';
import { UserContentApiService } from './api/user/user-content-api.service';
import { UserStateApiService } from './api/user/user-state-api.service';
import { ProfileServiceMock } from './community/profile/profile.service.mock';
import { PostServiceMock } from './community/post/post.service.mock';
import { FeedServiceMock } from './community/feed/feed.service.mock';
import { CollectionServiceMock } from './community/collection/collection.service.mock';
import { ResourceAccessServiceMock } from './community/access-control/resource-access.service.mock';
import { AuthenticationServiceMock } from './authentication/authentication.service.mock';
import { UserContentApiServiceMock } from './api/user/user-content-api-service-mock.service';
import { UserStateApiServiceMock } from './api/user/user-state-api-service-mock.service';
import { FileUploadService } from './community/post/file-upload.service';
import { FileUploadServiceMock } from './community/post/file-upload.service.mock';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import { UserApiService } from './api/user/user-api.service';
import { UserApiServiceMock } from './api/user/user-api.service.mock';
import { ProfileServiceImplementation } from './community/profile/profile.service.implementation';
import { PostServiceImplementation } from './community/post/post.service.implementation';
import { FeedServiceImplementation } from './community/feed/feed.service.implementation';
import { CollectionServiceImplementation } from './community/collection/collection.service.implementation';
import { ResourceAccessServiceImplementation } from './community/access-control/resource-access.service.implementation';
import { FirebaseAuthenticationService } from './authentication/firebase/firebase-authentication.service';
import { UserContentApiServiceImplementation } from './api/user/user-content-api-service-implementation.service';
import { UserStateApiServiceImplementation } from './api/user/user-state-api-service-implementation.service';
import { UserApiServiceImplementation } from './api/user/user-api.service.implementation';
import { FileUploadServiceImplementation } from './community/post/file-upload.service.implementation';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()), // todo: use only one provideHttpClient(...)
    {provide: HTTP_INTERCEPTORS, useClass: FirebaseAuthenticatorInterceptor, multi: true},

    /*** Mocks ***/
    // {provide: ProfileService, useClass: ProfileServiceMock},
    // {provide: PostService, useClass: PostServiceMock},
    // {provide: FeedService, useClass: FeedServiceMock},
    // {provide: CollectionService, useClass: CollectionServiceMock},
    // {provide: ResourceAccessService, useClass: ResourceAccessServiceMock},
    // {provide: AuthenticationService, useClass: AuthenticationServiceMock},
    // {provide: UserContentApiService, useClass: UserContentApiServiceMock},
    // {provide: UserStateApiService, useClass: UserStateApiServiceMock},
    // {provide: FileUploadService, useClass: FileUploadServiceMock},
    // {provide: UserApiService, useClass: UserApiServiceMock},

    /*** Implementations ***/
    {provide: ProfileService, useClass: ProfileServiceImplementation},
    {provide: PostService, useClass: PostServiceImplementation},
    {provide: FeedService, useClass: FeedServiceImplementation},
    {provide: CollectionService, useClass: CollectionServiceImplementation},
    {provide: ResourceAccessService, useClass: ResourceAccessServiceImplementation},
    {provide: AuthenticationService, useClass: FirebaseAuthenticationService},
    {provide: UserContentApiService, useClass: UserContentApiServiceImplementation},
    {provide: UserStateApiService, useClass: UserStateApiServiceImplementation},
    {provide: UserApiService, useClass: UserApiServiceImplementation},
    {provide: FileUploadService, useClass: FileUploadServiceImplementation},


    provideHttpClient(withInterceptors([loadingInterceptor])), // todo: use only one provideHttpClient(...)
    provideMarkdown({
      sanitize: SecurityContext.NONE,
      markedExtensions: [gfmHeadingId()]
    }),
  ],
};
