const hostPath = 'https://leonardo.keldysh.io';

export const environment = {
  hostPath: hostPath,
  apiPath: hostPath + '/api/leonardo-service',
  firebaseConfig: {
    apiKey: "AIzaSyC1l50l_PJqrV1dnINxd61CO1Vfhempl5o",
    authDomain: "leonardo-c6355.firebaseapp.com",
    projectId: "leonardo-c6355",
    storageBucket: "leonardo-c6355.appspot.com",
    messagingSenderId: "544957154114",
    appId: "1:544957154114:web:21482988cde3e0a4207edb"
  },
  production: true
};
