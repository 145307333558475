import {environment} from "../../environments/environment";
import {ResourceId, RootResourceId} from "./domain/resource";
import {UUID} from "./community.routes";

export namespace CommunityApi {

  export function getCommunityProfiles(): string {
    return profile();
  }

  export function getCommunityProfile(creationTimestamp: number, communityId: string): string {
    return profile() + '/' + creationTimestamp + '/' + communityId;
  }

  export function createCommunityProfile(): string {
    return profile();
  }

  export function putCommunityProfile(creationTimestamp: number, communityId: string): string {
    return profile() + '/' + creationTimestamp + '/' + communityId;
  }

  function profile() {
    return resource('profile');
  }

  function community(): string {
    return environment.apiPath + '/community';
  }

  function resource(resourceType: string): string {
    return community() + '/' + resourceType;
  }

  function assignment(resourceType: string, assignmentId: string): string {
    return resource(resourceType) + '/' + assignmentId;
  }

  export function getFeeds(communityId: string, fromTimestamp: number): string {
    return feeds(communityId) + '/' + fromTimestamp;
  }

  export function getFeed(resourceId: ResourceId): string {
    return feed(resourceId.assignmentId, resourceId.creationTimestamp, resourceId.uuid);
  }

  export function createFeed(communityId: string): string {
    return feeds(communityId);
  }

  export function putFeed(communityId: string, creationTimestamp: number, id: string): string {
    return feed(communityId, creationTimestamp, id);
  }

  function feed(communityId: string, creationTimestamp: number, id: string): string {
    return feeds(communityId) + '/' + creationTimestamp + '/' + id;
  }

  function feeds(communityId: string): string {
    return assignment('feed', communityId);
  }

  export function getCollections(communityId: string, fromTimestamp: number): string {
    return collections(communityId) + '/' + fromTimestamp;
  }

  export function getCollection(resourceId: ResourceId): string {
    return collection(resourceId.assignmentId, resourceId.creationTimestamp, resourceId.uuid);
  }

  export function createCollection(communityId: string): string {
    return collections(communityId);
  }

  export function putCollection(communityId: string, creationTimestamp: number, id: string): string {
    return collection(communityId, creationTimestamp, id);
  }

  function collection(communityId: string, creationTimestamp: number, id: string): string {
    return collections(communityId) + '/' + creationTimestamp + '/' + id;
  }

  function collections(communityId: string): string {
    return assignment('collection', communityId);
  }

  export function getPost(resourceId: ResourceId) {
    return posts(resourceId.assignmentId, resourceId.creationTimestamp) + '/' + resourceId.uuid;
  }

  export function posts(assignmentId: string, timestamp: number) {
    return post(assignmentId) + '/' + timestamp;
  }

  export function post(assignmentId: string) {
    return assignment('post', assignmentId);
  }

  export function accessControl(resourceId: ResourceId) {
    return environment.apiPath + '/access-control' + '/' + resourceId.assignmentId + '/' + resourceId.creationTimestamp + '/' + resourceId.uuid;
  }

  export function writePermission(communityId: RootResourceId) {
    return environment.apiPath + '/write-permission' + '/' + communityId.creationTimestamp + '/' + communityId.uuid;
  }
}
