import {Routes} from '@angular/router';
// import {FeaturesComponent} from "./features/features.component";
import {PlansComponent} from "./plans/plans.component";
import {authenticationGuard} from "./authentication.guard";
import {LegalComponent} from "./legal/legal.component";
import {PostComponent} from "./post/post.component";

export enum AppRoutes {
  community = 'community',
  plans = 'plans',
  legal = 'legal',
  myContent = 'my-content'
}

export const routes: Routes = [
  {path: '', redirectTo: AppRoutes.myContent, pathMatch: 'full'},
  {
    path: AppRoutes.community,
    canActivate: [authenticationGuard],
    loadChildren: () => import('./community/community.routes').then(m => m.routes)
  },
  // {path: '', component: PostComponent},
  // {path: 'feed-creator', component: FeedCreatorViewComponent},
  // {path: 'features', component: FeaturesComponent},
  // {path: AppRoutes.plans, component: PlansComponent},
  // {path: AppRoutes.legal, component: LegalComponent},
  {
    path: AppRoutes.myContent,
    canActivate: [authenticationGuard],
    loadChildren: () => import('./secure/my-content-routes').then(m => m.routes)
  }
];
