import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginComponent} from "../login.component";
import {Modal} from "bootstrap";
import {Router} from "@angular/router";
import {LoginModalService} from "./login-modal.service";
import { MyContentRoutes } from '../../secure/my-content-routes';
import { AppRoutes } from '../../app.routes';

@Component({
  selector: 'app-login-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LoginComponent
  ],
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss'
})
export class LoginModalComponent implements AfterViewInit {

  @ViewChild('loginModal') loginModal!: ElementRef;

  modal!: Modal;
  modalTitle: string = 'Login';

  requestedUrl!: string | null;

  constructor(
    protected loginModalService: LoginModalService,
    private router: Router
  ) {
  }

  ngAfterViewInit() {
    this.modal = new Modal(this.loginModal.nativeElement);

    this.loginModalService.loginRequest.subscribe(value => {
      this.requestedUrl = value
      this.show();
    })

    // this.loginModalService.getModalState().subscribe(isVisible => {
    //   if (isVisible) {
    //     this.show();
    //   } else {
    //     this.hide();
    //   }
    // });
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  onModalTitleChange(title: string) {
    this.modalTitle = title;
  }

  hideAndRoute() {
    this.hide();
    if (this.requestedUrl) {
      // console.log("routing to requested url: " + this.requestedUrl);
      void this.router.navigate([this.requestedUrl]);
    }
    else {
      // console.log("routing to default entry point ... ");
      void this.router.navigate([AppRoutes.myContent, MyContentRoutes.myInterests]);
    }
  }
}
