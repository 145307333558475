import { Injectable } from '@angular/core';
import {ResourceId, RootResourceId} from '../domain/resource';
import { Observable, switchMap, tap } from 'rxjs';
import { CommunityApi } from '../community.api';
import { AccessLevel, AccessSetupData, ResourceAccessService } from './resource-access.service';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../profile/profile.service';

@Injectable({
    providedIn: 'root',
  })
export class ResourceAccessServiceImplementation extends ResourceAccessService {

  constructor(private httpClient: HttpClient, profileService: ProfileService) {
    super(profileService);
  }

  setAccessLevel(resourceId: ResourceId, accessLevel: AccessLevel) {
    return this.profileService
      .activeCommunity()
      .pipe(switchMap(communityProfileResource => {
          const body: AccessSetupData = {communityId: communityProfileResource, accessLevel: accessLevel};
          return this.setupAccessLevel(resourceId, body);
        }
      ))
  }

  getAccessLevel(resourceId: ResourceId): Observable<AccessSetupData> {
    return this.httpClient.get<AccessSetupData>(CommunityApi.accessControl(resourceId))
  }

  private setupAccessLevel(resourceId: ResourceId, accessSetupData: AccessSetupData) {
    return this.httpClient.put<void>(CommunityApi.accessControl(resourceId), accessSetupData)
  }

  checkCommunityWriteAccess(communityId: RootResourceId): Observable<boolean> {
    return this.httpClient
      .get<boolean>(CommunityApi.writePermission(communityId))
      .pipe(tap(mayWrite => this.mayWriteSubject.next(mayWrite)))
  }
}
