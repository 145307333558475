import { inject, Injectable } from '@angular/core';
import { UserContentApiService } from './user-content-api.service';
import {Observable, tap} from 'rxjs';
import { Community, CommunityProfile } from '../../community/domain/community-profile';
import { ResourceId, RootResource } from '../../community/domain/resource';
import { ContentItem } from './domain/content-item';
import { HttpClient } from '@angular/common/http';
import { UserApi } from './user-api';
import {ContentItemWithCommunity} from "../../secure/domain/content-item-with-community";

@Injectable({
  providedIn: 'root'
})
export class UserContentApiServiceImplementation extends UserContentApiService {
  private readonly httpClient = inject(HttpClient);

  override getFollowedContent(): Observable<ContentItemWithCommunity[]> {
    return this.httpClient.get<ContentItemWithCommunity[]>(UserApi.getContent);
  }
  override getFollowedCommunities(): Observable<RootResource<CommunityProfile>[]> {
    return this.httpClient.get<RootResource<CommunityProfile>[]>(UserApi.getFollowedCommunities);
  }
  override setUserOpenedFeed(resource: ResourceId): Observable<void> {
    return this.httpClient.post<void>(UserApi.followFeed, resource);
  }
  override setUserOpenedCollection(resource: ResourceId): Observable<void> {
    return this.httpClient.post<void>(UserApi.followCollection, resource);
  }
  override getCommunitiesWithCreatorRights(): Observable<RootResource<CommunityProfile>[]> {
    return this.httpClient.get<RootResource<CommunityProfile>[]>(UserApi.getContributingCommunities);
  }
}
